<template>
  <div>
    <d-b-table
        title="Unpublished Recommendation"
        :load-path="sever_address"
        :delete-path="sever_address"
        :patch-path="sever_address"
        :show-index="false"
        :columns="{
          'Title': 'title',
          'Url': 'url',
          'Text': 'text',
          'Type': 'type'
        }"
        :patch-fields="{
          'Title':'title',
          'Text': 'text',
          'Url': 'url',
          'Type': 'type'
         }"
        patch-dialog-title="Edit Recommendation"
    />

    <d-b-table
        title="Published Recommendation"
        :load-path="published_sever_address"
        :delete-path="sever_address"
        :patch-path="sever_address"
        :show-index="false"
        :columns="{
          'Title': 'title',
          'Url': 'url',
          'Text': 'text',
          'Type': 'type'
        }"
        :patch-fields="{
          'Title':'title',
          'Text': 'text',
          'Url': 'url',
          'Type': 'type'
         }"
        patch-dialog-title="Edit Recommendation"
    />
  </div>
</template>
<script>

import DBTable from "@/components/DBTable";

export default {
  data() {
    return {
      sever_address: '/selfSupport/recommendation/',
      published_sever_address: '/selfSupport/recommendation/published/'
    }
  },
  methods: {},
  async mounted() {
  },
  components: {
    DBTable
  }
}
</script>
