var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('d-b-table',{attrs:{"title":"Unpublished Recommendation","load-path":_vm.sever_address,"delete-path":_vm.sever_address,"patch-path":_vm.sever_address,"show-index":false,"columns":{
        'Title': 'title',
        'Url': 'url',
        'Text': 'text',
        'Type': 'type'
      },"patch-fields":{
        'Title':'title',
        'Text': 'text',
        'Url': 'url',
        'Type': 'type'
       },"patch-dialog-title":"Edit Recommendation"}}),_c('d-b-table',{attrs:{"title":"Published Recommendation","load-path":_vm.published_sever_address,"delete-path":_vm.sever_address,"patch-path":_vm.sever_address,"show-index":false,"columns":{
        'Title': 'title',
        'Url': 'url',
        'Text': 'text',
        'Type': 'type'
      },"patch-fields":{
        'Title':'title',
        'Text': 'text',
        'Url': 'url',
        'Type': 'type'
       },"patch-dialog-title":"Edit Recommendation"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }